var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { StatCard } from "../Card";
import { TextInput } from "../Input";
import { Radio } from "../Radio";
import { MultiSelectDropdown, } from "../MultiSelectDropdown";
import { StaticTable } from "../Table";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { GetCarInformationFromEVCCID } from "ee-utils";
import { useSearchParams } from "react-router-dom";
var StatsBreakdown = function (_a) {
    var loyaltyTransactionStats = _a.loyaltyTransactionStats, isNoc = _a.isNoc;
    var loyaltyTableColumns = [
        {
            header: "Possible Car Manufacturer",
            accessorFn: function (row) {
                var _a, _b, _c;
                return ((_b = (_a = row.carInformation.confirmedCarManufacturers) === null || _a === void 0 ? void 0 : _a.join(", ")) !== null && _b !== void 0 ? _b : (_c = row.carInformation.suspectedCarManufacturers) === null || _c === void 0 ? void 0 : _c.join(", "));
            },
        },
        {
            header: "Sessions",
            accessorKey: "count",
        },
    ];
    if (isNoc) {
        loyaltyTableColumns.push({
            header: "Latest Transaction Id (NOC-only)",
            accessorKey: "latestTransaction.transactionDetail.transactionId",
        });
    }
    loyaltyTableColumns = loyaltyTableColumns.concat([
        {
            header: "Last Session Date",
            accessorFn: function (row) {
                return new Date(row.latestTransaction.chargingSessionEndUtc);
            },
            cell: function (info) {
                return info.getValue().toLocaleString();
            },
        },
        {
            header: "Total Amount Spent",
            accessorFn: function (row) {
                // There isn't an aggregation for
                return "$".concat(row.totalAmountSpent.toFixed(2));
            },
        },
        {
            header: "Total Energy Delivered",
            accessorKey: "totalConsumedEnergyKwh",
        },
    ]);
    var tableData = useMemo(function () {
        return loyaltyTransactionStats.top10EvccIds.map(function (eData) { return (__assign(__assign({}, eData), { carInformation: GetCarInformationFromEVCCID(eData.evccid) })); });
    }, [loyaltyTransactionStats.top10EvccIds]);
    return (React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
        React.createElement(StatCard, { title: "Total Sessions", value: "".concat(loyaltyTransactionStats.totalSessions) }),
        React.createElement(StatCard, { title: "Total Sessions By Repeat Customers", value: "".concat(loyaltyTransactionStats.totalSessionsByRepeatCustomers) }),
        React.createElement(StatCard, { title: "Unique Customers", value: "".concat(loyaltyTransactionStats.numberOfuniqueCustomers) }),
        React.createElement(StatCard, { title: "Total Repeat Customers", value: "".concat(loyaltyTransactionStats.numberOfRepeatCustomers) }),
        React.createElement(StatCard, { title: "Percentage of Repeat Customers", value: "".concat(Math.round((loyaltyTransactionStats.numberOfRepeatCustomers /
                loyaltyTransactionStats.numberOfuniqueCustomers) *
                10000) / 100, " %") }),
        React.createElement(StatCard, { title: "Percentage of Repeat Customer Sessions", value: "".concat(Math.round((loyaltyTransactionStats.totalSessionsByRepeatCustomers /
                loyaltyTransactionStats.totalSessions) *
                10000) / 100, " %") }),
        React.createElement("div", { className: "flex flex-col gap-y-2" },
            React.createElement(StaticTable, { data: tableData, columnDefinitions: loyaltyTableColumns, loading: false, buildRowLink: function (row) {
                    return "/transaction/".concat(row.latestTransaction.transactionDetail.transactionId);
                } }))));
};
export var CustomerLoyaltyStats = function (_a) {
    var _b;
    var organizationId = _a.organizationId, isNoc = _a.isNoc;
    var _c = useAuth0(), getAccessTokenSilently = _c.getAccessTokenSilently, isLoading = _c.isLoading, isAuthenticated = _c.isAuthenticated;
    var _d = useSearchParams(), searchParams = _d[0], setSearchParams = _d[1];
    var isValidDate = function (date) {
        return !isNaN(date.getTime());
    };
    var formatDateString = function (dateString) {
        if (dateString) {
            var date = new Date(dateString);
            if (isValidDate(date)) {
                return date.toISOString();
            }
        }
        return null;
    };
    var TimeRangeSelectorOption = {
        OPTION_CUSTOM_RELATIVE_RANGE: "CUSTOM_RELATIVE_RANGE",
        OPTION_LAST_QUARTER: "LAST_QUARTER",
        OPTION_LAST_7_DAYS: "LAST_7_DAYS",
        OPTION_LAST_30_DAYS: "LAST_30_DAYS",
        OPTION_ALL_TIME: "ALL_TIME",
    };
    var quarterBasedRanges = [TimeRangeSelectorOption.OPTION_LAST_QUARTER];
    var radioItems = [
        {
            name: "All Time",
            value: TimeRangeSelectorOption.OPTION_ALL_TIME,
        },
        {
            name: "Custom Relative Range",
            value: TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE,
        },
        {
            name: "Last 30 Days",
            value: TimeRangeSelectorOption.OPTION_LAST_30_DAYS,
        },
        {
            name: "Last 7 Days",
            value: TimeRangeSelectorOption.OPTION_LAST_7_DAYS,
        },
        {
            name: "Last Quarter",
            value: TimeRangeSelectorOption.OPTION_LAST_QUARTER,
        },
    ].reverse();
    var _e = useState(function () {
        var param = searchParams.get("radioSelection");
        return param ? param : TimeRangeSelectorOption.OPTION_LAST_QUARTER;
    }), radioSelection = _e[0], setRadioSelection = _e[1];
    var _f = useMemo(function () {
        var currentQuarter = radioSelection === TimeRangeSelectorOption.OPTION_LAST_QUARTER
            ? Math.ceil(new Date().getMonth() / 4)
            : undefined;
        var currentYear = radioSelection === TimeRangeSelectorOption.OPTION_LAST_QUARTER
            ? new Date().getFullYear()
            : undefined;
        return { currentQuarter: currentQuarter, currentYear: currentYear };
    }, [radioSelection]), currentQuarter = _f.currentQuarter, currentYear = _f.currentYear;
    var _g = useState(function () {
        var param = searchParams.get("siteIds");
        return param ? param.split(",") : [];
    }), sitesMultiSelectState = _g[0], setSitesMultiSelectState = _g[1];
    var relativeDayTextInput = useRef(null);
    var absoluteBeginInput = useRef(null);
    var absoluteEndInput = useRef(null);
    var updateSearchParams = function () {
        var _a, _b;
        searchParams.set("radioSelection", radioSelection);
        searchParams.set("siteIds", sitesMultiSelectState.join(","));
        var fromDate = formatDateString(((_a = absoluteBeginInput.current) === null || _a === void 0 ? void 0 : _a.value) || null);
        var toDate = formatDateString(((_b = absoluteEndInput.current) === null || _b === void 0 ? void 0 : _b.value) || null);
        if (fromDate) {
            searchParams.set("from", fromDate);
        }
        else {
            searchParams.delete("from");
        }
        if (toDate) {
            searchParams.set("to", toDate);
        }
        else {
            searchParams.delete("to");
        }
        if (relativeDayTextInput.current) {
            searchParams.set("relativeDays", relativeDayTextInput.current.value.toString());
        }
        setSearchParams(searchParams);
    };
    useEffect(function () {
        updateSearchParams();
    }, [radioSelection, sitesMultiSelectState]);
    var apiSearchParams = new URLSearchParams();
    apiSearchParams.append("organizationId", "".concat(organizationId));
    var daysToMilliseconds = 1000 * 60 * 60 * 24;
    if (radioSelection == TimeRangeSelectorOption.OPTION_LAST_30_DAYS) {
        apiSearchParams.append("fromNowRelativeMilliseconds", "".concat(30 * daysToMilliseconds));
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_LAST_7_DAYS) {
        apiSearchParams.append("fromNowRelativeMilliseconds", "".concat(7 * daysToMilliseconds));
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE &&
        relativeDayTextInput.current) {
        var val = Number(relativeDayTextInput.current.value);
        if (val > 0) {
            apiSearchParams.append("fromNowRelativeMilliseconds", "".concat(val * daysToMilliseconds));
        }
    }
    else if (quarterBasedRanges.includes(radioSelection) &&
        currentQuarter &&
        currentYear) {
        var startMonth = (currentQuarter - 1) * 3;
        var endMonth = startMonth + 2;
        var from = new Date(currentYear, startMonth, 1);
        var to = new Date(currentYear, endMonth + 1, 0);
        apiSearchParams.append("from", from.toISOString());
        apiSearchParams.append("to", to.toISOString());
    }
    // Get the site list, for filtering by site.
    var siteList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    // Build the site list dropdown menu and state.
    var siteListDropdownMenu = siteList
        ? __spreadArray([
            { name: "All", value: "" }
        ], ((_b = siteList === null || siteList === void 0 ? void 0 : siteList.sites) !== null && _b !== void 0 ? _b : []).map(function (s) { return ({
            name: "".concat(s.id, ": ").concat(s.name),
            value: s.id,
        }); }), true) : [];
    if ((sitesMultiSelectState === null || sitesMultiSelectState === void 0 ? void 0 : sitesMultiSelectState.length) > 0) {
        sitesMultiSelectState.forEach(function (val) {
            return apiSearchParams.append("siteIds", val);
        });
    }
    var loyaltyTransactionStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/organization/loyalty/stats?").concat(apiSearchParams.toString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px] mt-4" },
        React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Time Range"),
            React.createElement(Radio, { items: radioItems, value: radioSelection, setValue: function (value) { return setRadioSelection(value); } })),
        radioSelection ==
            TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE && (React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Days Before Now"),
            React.createElement(TextInput, { type: "number", ref: relativeDayTextInput, defaultValue: searchParams.get("relativeDays") || "1" }))),
        React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Site"),
            React.createElement(MultiSelectDropdown, { items: siteListDropdownMenu, currentSelections: sitesMultiSelectState, onChange: setSitesMultiSelectState })),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        loyaltyTransactionStats && (React.createElement(React.Fragment, null,
            React.createElement(StatsBreakdown, { loyaltyTransactionStats: loyaltyTransactionStats, isNoc: isNoc }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%]" })))));
};
