import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { Dropdown } from "./Dropdown";
import { TextInput } from "./Input";
import { Radio } from "./Radio";
export var TimeHorizon;
(function (TimeHorizon) {
    TimeHorizon["DAY"] = "DAY";
    TimeHorizon["MONTH"] = "MONTH";
    TimeHorizon["YTD"] = "YTD";
    TimeHorizon["CUSTOM"] = "CUSTOM";
    TimeHorizon["ALL"] = "ALL";
})(TimeHorizon || (TimeHorizon = {}));
var DropDownBasedTimeHorizons = [
    TimeHorizon.DAY,
    TimeHorizon.MONTH,
    TimeHorizon.YTD,
];
export function getDateRange(horizon, startDate) {
    var start = new Date(startDate);
    var end = new Date(startDate);
    switch (horizon) {
        case TimeHorizon.DAY:
            start.setHours(0, 0, 0, 0);
            end.setHours(23, 59, 59, 999);
            break;
        case TimeHorizon.MONTH:
            start = new Date(start.getFullYear(), start.getMonth(), 1);
            end = new Date(start.getFullYear(), start.getMonth() + 1, 1);
            break;
        case TimeHorizon.YTD:
            start = new Date(start.getFullYear(), 0, 1);
            end = new Date(start.getFullYear() + 1, 0, 1);
            break;
        case TimeHorizon.ALL:
        default:
            end = new Date(start.getFullYear() + 1, 0, 1);
            start = new Date(0);
            break;
    }
    return [start, end];
}
export function GetHistoricalDateRanges(horizon, historicalPoints, startDate) {
    if (horizon == TimeHorizon.ALL) {
        // Max granularity is a year.
        horizon = TimeHorizon.YTD;
    }
    var ranges = [getDateRange(horizon, startDate)];
    for (var i = 0; i < historicalPoints - 1; ++i) {
        var start = new Date(ranges[i][0]);
        var end = new Date(ranges[i][1]);
        switch (horizon) {
            case TimeHorizon.DAY:
                start.setDate(start.getDate() - 1);
                end.setDate(end.getDate() - 1);
                break;
            case TimeHorizon.MONTH:
                start.setMonth(start.getMonth() - 1);
                end.setMonth(end.getMonth() - 1);
                break;
            default:
                start.setFullYear(start.getFullYear() - 1);
                end.setFullYear(end.getFullYear() - 1);
                break;
        }
        ranges.push([start, end]);
    }
    return ranges.reverse();
}
function getDateRangesBetweenDates(horizon, earliestDate, latestDate) {
    if (horizon == TimeHorizon.ALL) {
        // Max granularity is a year.
        horizon = TimeHorizon.YTD;
    }
    var currentDate = getDateRange(horizon, earliestDate);
    var lastDate = getDateRange(horizon, latestDate);
    var ranges = [];
    while (currentDate[0].getTime() <= lastDate[0].getTime()) {
        ranges.push([new Date(currentDate[0]), new Date(currentDate[1])]);
        switch (horizon) {
            case TimeHorizon.DAY:
                currentDate[0].setDate(currentDate[0].getDate() + 1);
                currentDate[1].setDate(currentDate[1].getDate() + 1);
                break;
            case TimeHorizon.MONTH:
                currentDate[0].setMonth(currentDate[0].getMonth() + 1);
                currentDate[1].setMonth(currentDate[1].getMonth() + 1);
                break;
            default:
                currentDate[0].setFullYear(currentDate[0].getFullYear() + 1);
                currentDate[1].setFullYear(currentDate[1].getFullYear() + 1);
                break;
        }
    }
    return ranges.reverse();
}
export function getSubDateRanges(horizon, startDate) {
    var dateRange = getDateRange(horizon, startDate);
    switch (horizon) {
        default:
        case TimeHorizon.DAY:
            return Array(24)
                .fill(dateRange[0])
                .map(function (d, i) {
                var start = new Date(d);
                var end = new Date(d);
                start.setHours(i, 0, 0, 0);
                end.setHours(i + 1, 0, 0, 0);
                var display = "".concat(start.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }), " - ").concat(end.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }));
                return [start, end, display];
            });
        case TimeHorizon.MONTH:
            return Array(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate())
                .fill(dateRange[0])
                .map(function (d, i) {
                var start = new Date(d);
                var end = new Date(d);
                start.setDate(i + 1);
                end.setDate(i + 2);
                var display = "".concat(start.toLocaleDateString());
                return [start, end, display];
            });
        case TimeHorizon.YTD:
            return Array(12)
                .fill(dateRange[0])
                .map(function (d, i) {
                var start = new Date(d);
                var end = new Date(d);
                start.setMonth(i);
                end.setMonth(i + 1);
                var display = "".concat(start.toLocaleString("default", {
                    month: "long",
                }));
                return [start, end, display];
            });
    }
}
export var DateRange = function (_a) {
    var value = _a.value, setValue = _a.setValue, hideAll = _a.hideAll;
    var range = getDateRange(value, new Date());
    var dateRangeText = value == TimeHorizon.DAY
        ? "".concat(range[0].toLocaleDateString())
        : "".concat(range[0].toLocaleDateString(), " - ").concat(range[1].toLocaleDateString());
    var items = [
        { name: TimeHorizon.DAY, value: TimeHorizon.DAY },
        { name: TimeHorizon.MONTH, value: TimeHorizon.MONTH },
        { name: TimeHorizon.YTD, value: TimeHorizon.YTD },
    ];
    if (!hideAll) {
        items.push({ name: TimeHorizon.ALL, value: TimeHorizon.ALL });
    }
    return (React.createElement("div", { className: "flex flex-row gap-x-[32px] items-center" },
        React.createElement(Radio, { items: items, value: value, setValue: setValue }),
        value != TimeHorizon.ALL && (React.createElement("label", { className: "font-N9 text-black-400" }, dateRangeText))));
};
export var DateRangeSelector = function (_a) {
    var _b, _c;
    var dateRange = _a.dateRange, setDateRange = _a.setDateRange, onHorizonChanged = _a.onHorizonChanged, firstDate = _a.firstDate, hideAll = _a.hideAll;
    var _d = useState(TimeHorizon.DAY), timeHorizon = _d[0], setTimeHorizon = _d[1];
    var setTimeHorizonAndResetDate = useCallback(function (horizon) {
        setTimeHorizon(horizon);
        if (onHorizonChanged) {
            onHorizonChanged(horizon);
        }
        setDateRange(getDateRange(horizon, new Date()));
    }, [getDateRange, onHorizonChanged, setDateRange, setTimeHorizon]);
    useEffect(function () {
        // This useEffect is used as an onComponentDidMount with the default state of TimeHorizon.DAY.
        setDateRange(getDateRange(timeHorizon, new Date()));
        if (onHorizonChanged) {
            onHorizonChanged(timeHorizon);
        }
    }, []);
    var onCustomDateChange = useCallback(function (startDateText, endDateText) {
        var start = startDateText ? new Date(startDateText) : dateRange[0];
        start.setHours(0, 0, 0, 0);
        var end = endDateText ? new Date(endDateText) : dateRange[1];
        end.setHours(23, 59, 59, 999);
        setDateRange([start, end]);
    }, [dateRange, setDateRange]);
    var dateRangeToString = function (horizon, range) {
        return horizon == TimeHorizon.DAY
            ? "".concat(range[0].toLocaleDateString())
            : "".concat(range[0].toLocaleDateString(), " - ").concat(range[1].toLocaleDateString());
    };
    var items = [
        { name: TimeHorizon.DAY, value: TimeHorizon.DAY },
        { name: TimeHorizon.MONTH, value: TimeHorizon.MONTH },
        { name: TimeHorizon.YTD, value: TimeHorizon.YTD },
        { name: TimeHorizon.CUSTOM, value: TimeHorizon.CUSTOM },
    ];
    if (!hideAll) {
        items.push({ name: TimeHorizon.ALL, value: TimeHorizon.ALL });
    }
    var historicalDropdownItems = useMemo(function () {
        if (!DropDownBasedTimeHorizons.includes(timeHorizon)) {
            return undefined;
        }
        var historicalRanges = getDateRangesBetweenDates(timeHorizon, firstDate, new Date());
        var historicalDropdownItems = historicalRanges.map(function (r) {
            var stringName = dateRangeToString(timeHorizon, r);
            return {
                name: stringName,
                value: r,
                selectedLabel: stringName,
            };
        });
        return historicalDropdownItems;
    }, [dateRangeToString, getDateRangesBetweenDates, timeHorizon, firstDate]);
    return (React.createElement("div", { className: "flex flex-row gap-x-[32px] items-center" },
        React.createElement(Radio, { items: items, value: timeHorizon, setValue: setTimeHorizonAndResetDate }),
        DropDownBasedTimeHorizons.includes(timeHorizon) && (React.createElement(Dropdown, { items: historicalDropdownItems, currentSelection: dateRange, onClick: setDateRange, comparator: function (d1, d2) {
                return d1.every(function (val, i) { return val.getTime() == d2[i].getTime(); });
            } })),
        timeHorizon === TimeHorizon.CUSTOM && (React.createElement(React.Fragment, null,
            React.createElement(TextInput, { type: "date", onChange: function (e) {
                    return onCustomDateChange(e.target.value, undefined);
                }, defaultValue: ((_b = dateRange[0]) === null || _b === void 0 ? void 0 : _b.toLocaleDateString()) ||
                    (firstDate === null || firstDate === void 0 ? void 0 : firstDate.toLocaleDateString()) ||
                    "" }),
            React.createElement(TextInput, { type: "date", onChange: function (e) {
                    return onCustomDateChange(undefined, e.target.value);
                }, defaultValue: ((_c = dateRange[1]) === null || _c === void 0 ? void 0 : _c.toLocaleDateString()) || "" })))));
};
