"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.promiseAllInBatches = void 0;
/**
 * Performs Promise.all excepts uses batching to avoid starting all promises at the same time. So if
 * these are requests it'll avoid sending all requests at once.
 *
 * @param promiseFunc The function that will take each item and perform an operation that will return a promise.
 * @param items The items to create promises for.
 * @param batchSize The max number of promises to have in flight at the same time.
 * @returns The results of the promises from the promiseFunc in the same order as the order of the items passed in.
 */
async function promiseAllInBatches(promiseFunc, items, batchSize) {
    let position = 0;
    let results = [];
    while (position < items.length) {
        const itemsForBatch = items.slice(position, position + batchSize);
        results = [
            ...results,
            ...(await Promise.all(itemsForBatch.map(promiseFunc))),
        ];
        position += itemsForBatch.length;
    }
    return results;
}
exports.promiseAllInBatches = promiseAllInBatches;
