"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.castBooleanQueryParameter = exports.castArrayQueryParameter = void 0;
// This function can be used when using a query parameter that is intended to be an array. The query
// parser may only see one value and in that case it won't make it into an array. The code below
// will convert it into an array if a value was specified.
function castArrayQueryParameter(arg) {
    return arg && !Array.isArray(arg) ? [arg] : arg;
}
exports.castArrayQueryParameter = castArrayQueryParameter;
// This function can be used when using a query parameter that is intended to be a boolean but is a
// string.  An html element might register this as a string and tracking it in a react state might
// want it to be a string to evaluate the html element correctly. So using this method will make it easier to just convert it in time client/server side.
function castBooleanQueryParameter(arg) {
    if (arg?.trim().toLowerCase() === "true") {
        return true;
    }
    else if (arg?.trim().toLowerCase() === "false") {
        return false;
    }
    else {
        return undefined;
    }
}
exports.castBooleanQueryParameter = castBooleanQueryParameter;
