"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupBy = void 0;
/**
 * Performs a groupBy function on the items passed in using the keyFunc to determine which field to group by.
 *
 * @param items The items to make groups out of.
 * @param keyFunc The function to take the item and determine what the key for grouping is.
 * @returns A Map which has the key based on the KeyFunc and an array of the corresponding items that have that key.
 */
function groupBy(items, keyFunc) {
    return Array.from(items).reduce((acc, item) => {
        const key = keyFunc(item);
        if (!acc.has(key)) {
            acc.set(key, []);
        }
        acc.get(key).push(item);
        return acc;
    }, new Map());
}
exports.groupBy = groupBy;
